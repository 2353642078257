<template>
<div class="group-course-analysis">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="group-course-analysis-cont">
<!--    <TheanalysisoftheStudents></TheanalysisoftheStudents>-->
    <StuAnalysis :courseId="courseId" :groupId="groupId"></StuAnalysis>
  </div>
</div>
</template>

<script>
// import TheanalysisoftheStudents from './theanalysisoftheStudents.vue';
import StuAnalysis from "../../course/components/StuAnalysis.vue";
export default {
  name: "groupCourseAnalysis.vue",
  components:{
    // TheanalysisoftheStudents,
    StuAnalysis
  },
  data(){
    return{
      courseId:'',
      groupId:'',
    }
  },
  created(){
    this.courseId = this.$route.query.courseId;
    this.groupId = this.$route.query.groupId;
  }
}
</script>

<style scoped lang="scss">
.group-course-analysis{
  padding: 20px;
  .group-course-analysis-cont{
    padding: 20px;
    background-color: #FFFFFF;
  }
}
</style>
